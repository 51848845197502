import React from "react"

const PrivacyPolicy = () => {
	React.useEffect(() => {
		window.location.href = "/privacypolicy"
	}, []);

	return null;
}

export default PrivacyPolicy;